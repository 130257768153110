.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 30px;
}

.button {
  color: white;
  background-color: #20262e;
  border: 1px solid white;
  @media (min-width: 768px) {
    &:hover {
      background-color: darken($color: #20262e, $amount: 5);
      color: white;
    }
  }
}

.timePickerWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 30px;
  color: white;
  background-color: #20262e;
  border-radius: 10px;
}

.selectedPeriodWrapper {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.title {
  font-size: 20px;
  font-weight: 400;
}
