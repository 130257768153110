:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.page {
  height: 100%;
}

.link {
  white-space: nowrap;
  background-color: #3eb456;
  color: #fff;
}
.link:hover {
  background-color: #37a14d;
  color: #fff;
}

.inputWrapper {
  position: relative;
}
@media (min-width: 1600px) {
  .inputWrapper {
    margin-top: 24px;
  }
}

.inputField {
  width: 70%;
  height: 25px;
  padding-right: 60px;
}

.inputWrapper button {
  position: absolute;
  height: 80%;
  width: 20%;
  border: none;
  right: 5px;
  top: 5px;
  background: url(../../../assets/images/icons/qr-code-scan-icon.svg) no-repeat center;
}

.inputWrapper button:focus {
  outline: none;
}

.modalScanner :global .modal-dialog {
  overflow: auto;
  max-width: 60vw !important;
  margin: 0 auto;
}
@media (max-width: 1000px) {
  .modalScanner :global .modal-dialog {
    max-width: 90vw !important;
  }
}
.modalScanner :global .modal-dialog .modal-content {
  background: white;
  align-content: center;
}
.modalScanner :global .modal-dialog .modal-header > button.close {
  color: black;
}