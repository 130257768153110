@import '../../../assets/stylesheets/colors.scss';

.page {
  height: 100%;
  max-width: 1500px;
}

.analyticsChart {
  width: 100%;
  @media (min-width: 1366px) {
    width: 65%;
    padding-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
