@import '../../../../assets/stylesheets/colors';

.title {
  font-size: 22px;
  margin-bottom: 5px;
  font-weight: 700;
}

.text {
  font-size: 18px;
}

.box {
  color: $gray-1;
  padding: 40px;
  box-shadow: 0px 3px 6px #00000019;
}
