.selector {
  position: absolute;
  display: grid;
  top: 20px;
  right: 20px;
  border-radius: 6px;
  background: white;
}
.selector label {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  margin: 0 6px;
}
.selector label:first-child {
  margin-top: 6px;
}
.selector label:last-child {
  margin-bottom: 6px;
}
.selector label input {
  vertical-align: -2px;
  margin-right: 4px;
}
.selector label span {
  vertical-align: middle;
}