.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (min-width: 1536px) {
  .wrapper {
    flex-direction: row;
  }
}

.table {
  order: 1;
  margin-bottom: 24px;
}
@media (min-width: 1536px) {
  .table {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 10px;
    order: 0;
    margin-bottom: 0;
  }
}

.map {
  margin-bottom: 24px;
}
@media (min-width: 1536px) {
  .map {
    width: 50%;
    padding-left: 10px;
    margin-bottom: 0;
  }
}

.tableHeader {
  position: sticky;
  top: 0;
  background-color: #f5f7f9;
}