@import '../../../../../assets/stylesheets/colors.scss';

.link {
  color: $green-1;
}

.listItem {
  white-space: nowrap;
}

.itemTruncatedText {
  text-overflow: ellipsis;
  min-width: 5rem;
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
}
