.cell {
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-weight: 700;

  &.green {
    background-color: #3eb456;
  }

  &.red {
    background-color: #f81919;
  }

  &.orange {
    background-color: #f89219;
  }

  &.violet {
    background-color: #b52bb0;
  }

  &.blue {
    background-color: #2e75d8;
  }
}
