@import '../../../../../assets/stylesheets/colors';

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.button {
  background-color: transparent;
  border: none;
}

.text {
  font-size: 14px;
  margin: 0;
}

.icon {
  color: $white;
  font-size: 24px;
}
