.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 1280px) {
  .formWrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.multiSelect {
  width: 100%;
}
@media (min-width: 1280px) {
  .multiSelect {
    width: 50%;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.75rem;
}

.error {
  color: red;
  font-size: 14px;
  text-align: center;
}

.info {
  color: #212529;
  font-size: 1rem;
  font-weight: 500;
}

.title {
  font-size: 1.2rem;
  margin-bottom: 0;
}