@import '../../../assets/stylesheets/colors.scss';

.insightPage {
  height: 100%;
  max-width: 1500px;
}

.arrowDown {
  color: $green-1;
}

.arrowUp {
  color: $orange-1;
}

.incursionChart {
  width: 100%;
  @media (min-width: 1366px) {
    width: 65%;
    padding-right: 20px;
    padding-bottom: 16px;
  }
}
