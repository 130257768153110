.noteRow {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.noteDate {
  font-weight: 700;
}

.noteAuthor {
  font-weight: 500;
}

.editBtn {
  align-self: center;
}
