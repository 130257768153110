@import '../../../../assets/stylesheets/colors';

.defaultBox {
  align-items: center;
  justify-content: center;
  color: $gray-1;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000019;
}

.hintBox {
  background-color: $dark-1;
  color: $white;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000019;
}
