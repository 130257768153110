@media (min-width: 768px) {
  .box {
    grid-column: 1/3;
  }
}
@media (min-width: 1600px) {
  .box {
    grid-column: 3/5;
  }
}

.itemId {
  margin-right: 10px;
  text-align: left;
  text-transform: uppercase;
}