.wrapper {
  position: relative;
  width: 100%;
  @media (min-width: 1366px) {
    width: 35%;
    padding-left: 20px;
  }
}

.buttonsWrapper {
  position: absolute;
  top: 16px;
  left: 36px;
  z-index: 1;
}

.button {
  padding: 2px 10px;
}
