@import '../../../../assets/stylesheets/colors';

.box {
  align-items: center;
  color: $gray-1;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000019;

  h3:nth-of-type(2) {
    margin-bottom: 10px;
  }
}

.title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}
