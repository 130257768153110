.title {
  font-weight: 700;
}

.cardsInfoWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1rem;
  width: 100%;
}
@media (min-width: 768px) {
  .cardsInfoWrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;
  }
}
@media (min-width: 1600px) {
  .cardsInfoWrapper {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}