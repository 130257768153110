.wrapper {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 100%;
  gap: 0.5rem;

  &:nth-child(2) {
    margin: 0.5rem auto;
  }
}

.contentSkeleton {
  width: 90%;
  height: 2rem;
  margin: 0 auto;
}

.skeleton {
  width: 100%;
  height: 2rem;
}
