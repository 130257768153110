.corePagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}
.corePagination button {
  min-width: 38px !important;
}
.corePagination .pageSizeOption {
  margin: 0 3px;
}

.corePagination:global(.small) * {
  font-size: 12px;
}
.corePagination:global(.small) button {
  min-width: 32px !important;
}