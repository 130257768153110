@import '../../../assets/stylesheets/colors.scss';

.wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;

  th,
  td {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  .thContent {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  th {
    padding: 0 7px 5px;
    font-weight: 700;
    color: $black;
    &:first-child {
      .thContent {
        justify-content: flex-start;
      }
    }
    &:last-child {
      .thContent {
        justify-content: flex-end;
      }
    }
  }

  td {
    color: $gray-1;
  }

  tbody {
    td {
      background-color: $white;
      border-color: $gray-border;
      border-style: solid;
      border-width: 1px 0 1px 0;
      &:first-child {
        border-radius: 5px 0 0 5px;
        border-width: 1px 0 1px 1px;
        &.dark {
          background-color: $dark-1;
          color: white;
        }
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
        border-width: 1px 1px 1px 0;
      }
    }
  }

  &.dark {
    th {
      border-bottom: 2px solid $white;
      padding: 0px;
      padding-bottom: 0.5rem;
      color: $white;
      font-size: 15px;

      .thContent {
        justify-content: flex-start;
      }

      &:last-child {
        .thContent {
          justify-content: flex-start;
        }
      }
    }

    tbody {
      td {
        width: 33.33%;
        background-color: $dark-1;
        border: 0;
        padding-right: 0.5rem;
        color: $white;
        font-size: 15px;
        text-align: left;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
