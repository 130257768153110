.title {
  font-weight: 700;
  margin-bottom: 0;
}

.multiSelect {
  width: 200px;
}

.chart {
  width: 100%;
  min-height: 300px;
}