.corePagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;

  button {
    min-width: 38px !important;
  }

  .pageSizeOption {
    margin: 0 3px;
  }
}

.corePagination:global(.small) {
  * {
    font-size: 12px;
  }

  button {
    min-width: 32px !important;
  }
}

// @media (max-width: 519px) {
//   table > thead {
//     display: none;
//   }
// }
