.card {
  background-color: #131922;
  color: #fff;
  padding: 40px;
}

.title {
  font-weight: 600;
  font-size: 22px;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin: 5px 0;
}
