@import '../../../../../assets/stylesheets/colors.scss';

.link {
  white-space: nowrap;
  background-color: $green-1;
  color: $white;
  font-size: 14px;
  text-transform: uppercase;
  height: 28px;
  &:hover {
    background-color: $green-1-dark;
    color: $white;
  }
}

.linkSmall {
  padding: 0 12px;
}

.iconWrapper {
  width: 28px;
  height: 26px;
  padding: 5px;
  margin: auto;
}

.icon {
  font-size: 16px;
}

.cell {
  padding: 8px 4px;
}
.iconCell {
  padding: 8px 1px;
}
