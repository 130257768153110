:export {
  mobileMinWidth: 520px;
  mobileMaxWidth: 519px;
}

.dashboardContainer {
  height: 100%;
}

.dashboardColumns {
  min-height: calc(100% - 60px);
}

.dashboardRow {
  display: flex;
}

.dashboardCol {
  overflow: auto;
}
.dashboardCol:first-of-type {
  margin-right: 8px;
}
.dashboardCol:last-of-type {
  margin-left: 8px;
}

.tableCol {
  width: 40%;
  min-width: 420px;
  padding: 3px 0;
}

.mapCol {
  width: 60%;
}

.activateButton {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
  padding: 0.375rem 1rem;
}
.activateButton i {
  vertical-align: middle;
}

.deleteSiteButton {
  border: none;
  background-color: transparent;
}
.deleteSiteButton:hover {
  text-decoration: underline;
}

.cardsRow {
  flex-wrap: wrap;
}

.deviceView :global .card {
  width: 31%;
  min-width: 175px;
}

@media (max-width: 1191px) {
  .dashboardCol {
    width: 100%;
  }
  .dashboardCol:first-of-type {
    margin-right: 0;
  }
  .dashboardCol:last-of-type {
    margin-left: 0;
  }
  .mapCol {
    order: -1;
  }
  .dashboardRow {
    flex-direction: column;
  }
}
@media (max-width: 440px) {
  .tableCol {
    min-width: unset;
  }
}
.subtitle {
  display: block;
  font-size: 14px;
  font-weight: 600;
  padding: 0.6rem 0;
  margin-top: 7px;
}

.map {
  height: 50vh;
  padding: 10px 0;
  min-height: 300px;
}
@media (min-width: 1192px) {
  .map {
    height: calc(100% - 40px);
  }
}

.ldsDualRing {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.ldsDualRing:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #222;
  border-color: #222 transparent #222 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.editFetchError {
  font-size: 11px;
  margin-left: 20px;
}