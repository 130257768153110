@import '../../../assets/stylesheets/colors.scss';

.success {
  background-color: $green-1;
  color: #fff;
  &:hover {
    background-color: $green-1-dark;
    color: #fff;
  }
}

.dark-1 {
  background-color: $dark-1;
  color: #fff;
  &:hover {
    background-color: $dark-1;
    color: #fff;
  }
  &:disabled {
    opacity: 0.65;
  }
}

button:disabled {
  cursor: not-allowed;
}
