.title {
  font-weight: 700;
}

.deviceNotes textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  resize: none;
}

.inputRow {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.inputRow button {
  margin-left: 8px;
}

.saveButton {
  white-space: nowrap;
  overflow: hidden;
  padding: 0.375rem 1rem;
  height: 38px;
}