@import '../../../assets/stylesheets/colors.scss';

.removeBtn {
  &:hover {
    background-color: #ff0000;
    color: white;
  }
}

.label {
  color: $black;
}
