.wrapper {
  display: grid;
  gap: 8px;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.datePicker {
  grid-column: 1/-1;
  justify-content: flex-end;
}
