.worksitesTable {
  border-spacing: 0 4px;
  border-collapse: separate;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  width: 100%;

  th,
  td {
    margin: 0;
    padding: 0.6rem;
    color: #707070;
    position: relative;
  }

  td {
    width: 25%;
    &:first-child,
    &:last-child {
      width: auto;
    }
  }

  :global {
    .basic-row {
      border-collapse: collapse;
      overflow: hidden;

      td {
        background-color: #ffffff;
        border-color: #d2d5d7;
        border-style: solid;
        border-width: 1px 0 1px 0;
        word-break: break-word;

        &:first-child {
          border-radius: 5px 0 0 5px;
          border-width: 1px 0 1px 1px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
          border-width: 1px;
        }
      }
    }

    .no-data-cell {
      border: 1px solid #d2d5d7;
      border-radius: 5px;
    }
  }
}

.centeredCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
