:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.insightPage {
  height: 100%;
  max-width: 1500px;
}

.arrowDown {
  color: #3eb456;
}

.arrowUp {
  color: #f26522;
}

.incursionChart {
  width: 100%;
}
@media (min-width: 1366px) {
  .incursionChart {
    width: 65%;
    padding-right: 20px;
    padding-bottom: 16px;
  }
}