.cardsInfoWrapper {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 1rem;
  width: 100%;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1366px) {
    width: 65%;
    padding-right: 20px;
  }
}
