.wrapper {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1280px) {
  .wrapper {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr)) 80px;
    column-gap: 16px;
  }
}

.link {
  grid-column: 1/3;
  white-space: break-spaces;
  font-size: 14px;
}

.statusWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.waiting {
  color: orange;
}

.processing {
  color: orange;
}

.ready {
  color: green;
}

.error {
  color: red;
}

.break {
  word-break: break-word;
}

.button {
  display: inline-block;
  font-size: 12px;
  background-color: black;
  color: white;
  border-radius: 5px;
  border: 0;
  padding: 2px 8px;
}
.button:hover {
  color: white;
}