.card-dark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #fff;
  background-color: #131922;
  padding: 40px;
}
