.small {
  width: 12px;
  height: 12px;
}

.medium {
  width: 18px;
  height: 18px;
}

.large {
  width: 24px;
  height: 24px;
}

.extraLarge {
  width: 34px;
  height: 34px;
}

.wrapper > svg {
  width: 100%;
  height: 100%;
}