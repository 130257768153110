@import '../../../assets/stylesheets/colors.scss';

.title {
  width: 50%;
  font-size: 30px;
  color: $gray-1;
  @media (min-width: 576px) {
    width: auto;
  }
}

.fetchInfo {
  width: 50%;
  font-size: 12px;
  text-align: right;
  @media (min-width: 576px) {
    width: auto;
    text-align: center;
  }
}
