@import '../../../../../assets/stylesheets/colors';

.percentageChange {
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 1;
}

.green {
  color: $green-1;
  font-weight: bold;
}

.red {
  color: $orange-1;
  font-weight: bold;
}

.iconArrow {
  margin-right: 4px;
  font-size: 21px;
}

.boxHeader {
  margin-bottom: 10px;
}

.title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.button {
  background-color: transparent;
  border: none;
}

.iconInfo {
  color: $gray-1;
}

.text {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 5px;
}
