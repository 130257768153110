@import '../../../../../assets/stylesheets/colors.scss';

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  margin: 20px 0;
  @media (min-width: 768px) {
    margin: 24px 0 20px 0;
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.active {
  background-color: $green-1 !important;
}
