.wrapper {
  display: flex;
  position: relative;
  transform: translate(-10px, -100%);
  transition: 0s;
  z-index: 9;
}

.mapMarkerIcon:hover {
  cursor: pointer;
}
.mapMarkerIcon:hover + .mapMarkerTooltip {
  display: block;
}

.box {
  display: flex;
  align-items: center;
  height: 35px;
  border-radius: 5px;
  margin-left: 10px;
  background-color: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
}

.boxIcon {
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
  background-color: #131922;
  color: white;
  padding: 4px;
}
.boxIcon:hover {
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.boxText {
  display: flex;
  flex-direction: column;
  padding: 6px;
}

.text {
  font-size: 12px;
  white-space: nowrap;
  color: black;
}