.button {
  background-color: transparent;
  border: 0;
  width: 100%;
  height: 100%;
  min-width: 50px;
  &:focus {
    outline: none;
  }
}
