.periodInputWrapper {
  flex: 1;
  position: relative;
}
.periodInput {
  padding: 0 30px 0 10px;
  color: #fff;
  background-color: #20262e !important;
  border: 1px solid #fff;
}

.label {
  width: 75px;
}

.insideIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-55%);
  right: 5px;
}

:global {
  .flatpickr-input.form-control[readonly] {
    background-color: white;
  }
}
