.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0980392157);
  padding: 10px;
  background-color: #fff;
  row-gap: 20px;
  column-gap: 10px;
}
@media (min-width: 1536px) {
  .wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.timestamp {
  color: #999999;
}

.description {
  color: #333333;
}