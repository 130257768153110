.wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  width: 100%;
  gap: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.identitiesWrapper {
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: space-between;
    flex: 1;
  }
}

.identities {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.identity {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-bottom: 15px;
  &:last-child {
    margin-right: 0;
  }
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.idTitle {
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.idValue {
  font-size: 14px;
  font-weight: 700;
  word-break: break-all;
}

.filters {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 16px;
  flex: 1;
}
