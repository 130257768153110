.page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  align-content: flex-start;
}

.map {
  width: 100%;
  margin-bottom: 24px;
}
@media (min-width: 768px) {
  .map {
    width: 49%;
    order: 1;
    margin-bottom: 0;
  }
}

.infoSection {
  width: 100%;
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .infoSection {
    width: 49%;
    padding-bottom: 0;
  }
}