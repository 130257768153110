@import '../../../../assets/stylesheets/colors.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 20px;
  font-size: 14px;

  &.dark {
    background-color: $dark-1;
    border-top: 2px solid $white;
    padding-top: 0.5rem;
    color: $white;

    button {
      color: $white;
      border-color: $white;
    }
  }
}

.pagination {
  grid-column: 2/2;
}

.pageCount {
  @media (min-width: 576px) {
    border-right: 1px solid $gray-border;
    border-left: 1px solid $gray-border;
  }
}

.totalCount {
  @media (min-width: 576px) {
    border-right: 1px solid $gray-border;
  }
}

.select {
  padding: 4px 8px;
  border: 1px solid $gray-border;
  border-radius: 4px;
}

.button {
  line-height: 1;
  font-size: 14px;
}
