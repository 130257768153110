.wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.0980392157);
}

.header {
  margin-bottom: 10px;
}

.content {
  padding-bottom: 10px;
  width: 75%;
  margin: 0 auto;
}

.contentSkeleton {
  height: 20px;
  margin-bottom: 8px;
}

.footer {
  width: 90%;
  margin: 0 auto;
}

.skeleton {
  height: 30px;
  margin-bottom: 10px;
}