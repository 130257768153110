.wrapper {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 20px;
  flex-grow: 1;
  min-height: 300px;
}
@media (min-width: 1280px) {
  .wrapper {
    overflow-y: auto;
    flex-basis: 1px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 25px;
  padding-left: 20px;
  margin-bottom: 0;
}