@media (max-width: 519px) {
  select,
  textarea,
  input[type=text],
  input[type=password],
  input[type=datetime],
  input[type=datetime-local],
  input[type=date],
  input[type=month],
  input[type=time],
  input[type=week],
  input[type=number],
  input[type=email],
  input[type=tel],
  input[type=url] {
    font-size: 16px;
  }
}