:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.percentageChange {
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 1;
}

.green {
  color: #3eb456;
  font-weight: bold;
}

.red {
  color: #f26522;
  font-weight: bold;
}

.iconArrow {
  margin-right: 4px;
  font-size: 21px;
}

.boxHeader {
  margin-bottom: 10px;
}

.title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
}

.button {
  background-color: transparent;
  border: none;
}

.iconInfo {
  color: #333333;
}

.text {
  font-size: 40px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 5px;
}