.wrapper {
  margin-top: 30px;
}

.dataWrapper {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.dataSkeleton {
  height: 53px;
  margin-bottom: 20px;
  width: 200px;
}

.timestampSkeleton {
  height: 24px;
  margin-bottom: 20px;
  width: 200px;
}
