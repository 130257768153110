:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.wrapper {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}
.table th,
.table td {
  font-size: 14px;
  text-align: center;
  margin: 0;
}
.table .thContent {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.table th {
  padding: 0 7px 5px;
  font-weight: 700;
  color: #000;
}
.table th:first-child .thContent {
  justify-content: flex-start;
}
.table th:last-child .thContent {
  justify-content: flex-end;
}
.table td {
  color: #333333;
}
.table tbody td {
  background-color: #fff;
  border-color: #ced4da;
  border-style: solid;
  border-width: 1px 0 1px 0;
}
.table tbody td:first-child {
  border-radius: 5px 0 0 5px;
  border-width: 1px 0 1px 1px;
}
.table tbody td:first-child.dark {
  background-color: #131922;
  color: white;
}
.table tbody td:last-child {
  border-radius: 0 5px 5px 0;
  border-width: 1px 1px 1px 0;
}
.table.dark th {
  border-bottom: 2px solid #fff;
  padding: 0px;
  padding-bottom: 0.5rem;
  color: #fff;
  font-size: 15px;
}
.table.dark th .thContent {
  justify-content: flex-start;
}
.table.dark th:last-child .thContent {
  justify-content: flex-start;
}
.table.dark tbody td {
  width: 33.33%;
  background-color: #131922;
  border: 0;
  padding-right: 0.5rem;
  color: #fff;
  font-size: 15px;
  text-align: left;
}
.table.dark tbody td:last-child {
  padding-right: 0;
}