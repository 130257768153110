.cell {
  padding: 0.25rem 0.5rem;
  text-align: center;
  font-weight: 700;
}
.cell.green {
  background-color: #3eb456;
}
.cell.red {
  background-color: #f81919;
}
.cell.orange {
  background-color: #f89219;
}
.cell.violet {
  background-color: #b52bb0;
}
.cell.blue {
  background-color: #2e75d8;
}