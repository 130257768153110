@import '../../../assets/stylesheets/colors.scss';

.page {
  height: 100%;
}

.link {
  white-space: nowrap;
  background-color: $green-1;
  color: $white;
  &:hover {
    background-color: $green-1-dark;
    color: $white;
  }
}

.inputWrapper {
  @media (min-width: 1600px) {
    margin-top: 24px;
  }
  position: relative;
}

.inputField {
  width: 70%;
  height: 25px;
  padding-right: 60px;
}

.inputWrapper button {
  position: absolute;
  height: 80%;
  width: 20%;
  border: none;
  right: 5px;
  top: 5px;
  background: url(../../../assets/images/icons/qr-code-scan-icon.svg) no-repeat center;
}
.inputWrapper button:focus {
  outline: none;
}

.modalScanner {
  :global {
    .modal-dialog {
      overflow: auto;
      max-width: 60vw !important;
      margin: 0 auto;
      @media (max-width: 1000px) {
        max-width: 90vw !important;
      }
      .modal-content {
        background: white;
        align-content: center;
      }

      .modal-header > button.close {
        color: black;
      }
    }
  }
}
