.map {
  width: 100%;
  height: 100%;
  position: relative;
}

.searchBoxWrapper {
  position: absolute;
  bottom: 25px;
  left: 10px;
  width: 250px;
}
@media (min-width: 640px) {
  .searchBoxWrapper {
    bottom: auto;
    left: auto;
    top: 10px;
    right: 10px;
  }
}