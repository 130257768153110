.pointMarker {
  width: 6px !important;
  height: 6px;
  border-radius: 100%;
  background: black;
  position: relative;
  display: flex;
  width: max-content;
  align-items: center;
  transform: translate(-3px, -4px);
}
.pointMarker:hover {
  cursor: pointer;
}