.box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 240px;
}

.label {
  font-weight: 700;
  color: black;
  margin-bottom: 0;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.actionLink {
  border: 1px solid #d4d9df;
  border-radius: 5px;
  padding: 1rem;
  width: 240px;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: 1.25rem;
  color: black;
}
.actionLink:focus, .actionLink:hover {
  outline: none;
  text-decoration: none;
  color: black;
}
.actionLink.active {
  border-color: #3eb456;
  background: rgba(178, 225, 187, 0.1);
}

.actionLinkText {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 0;
  font-size: 14px;
  text-align: left;
}

.button {
  width: 240px;
}

.dateWrapper {
  justify-content: flex-start !important;
}