.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  row-gap: 14px;
  column-gap: 16px;
}

.listWithElements {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 600px;
}

.noData {
  text-align: center;
  color: #999999;
}

.title {
  font-size: 1.2rem;
}