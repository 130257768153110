:export {
  darkOrange: #f26522;
  darkBlueGrey: #1d3a50;
  lightGreen: #3eb456;
  lightGrey: #707070;
  greenSuccess: #3eb456;
  blueBoxShadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  blueFocusBorder: #80bdff;
  grayBorder: #ced4da;
  graySecondary: #6c757d;
  red: #ff0000;
  markerBlue: #2294f9;
  markerGreen: #01e34d;
  grayText: #333333;
  blue1: #2e75d8;
  gray2: #4b4a4a;
  black: #000;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 20px;
  font-size: 14px;
}
.wrapper.dark {
  background-color: #131922;
  border-top: 2px solid #fff;
  padding-top: 0.5rem;
  color: #fff;
}
.wrapper.dark button {
  color: #fff;
  border-color: #fff;
}

.pagination {
  grid-column: 2/2;
}

@media (min-width: 576px) {
  .pageCount {
    border-right: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
  }
}

@media (min-width: 576px) {
  .totalCount {
    border-right: 1px solid #ced4da;
  }
}

.select {
  padding: 4px 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.button {
  line-height: 1;
  font-size: 14px;
}