.internalId {
  font-size: 12px;
  font-weight: bold;
  color: black;
  min-width: 120px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.id {
  font-size: 11px;
  color: #646464;
}