.worksitesTable {
  border-spacing: 0 4px;
  border-collapse: separate;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  width: 100%;
}
.worksitesTable th,
.worksitesTable td {
  margin: 0;
  padding: 0.6rem;
  color: #707070;
  position: relative;
}
.worksitesTable td {
  width: 25%;
}
.worksitesTable td:first-child, .worksitesTable td:last-child {
  width: auto;
}
.worksitesTable :global .basic-row {
  border-collapse: collapse;
  overflow: hidden;
}
.worksitesTable :global .basic-row td {
  background-color: #ffffff;
  border-color: #d2d5d7;
  border-style: solid;
  border-width: 1px 0 1px 0;
  word-break: break-word;
}
.worksitesTable :global .basic-row td:first-child {
  border-radius: 5px 0 0 5px;
  border-width: 1px 0 1px 1px;
}
.worksitesTable :global .basic-row td:last-child {
  border-radius: 0 5px 5px 0;
  border-width: 1px;
}
.worksitesTable :global .no-data-cell {
  border: 1px solid #d2d5d7;
  border-radius: 5px;
}

.centeredCell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}