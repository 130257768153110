.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 40px;
}

.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  border: 1px solid #ced4da;
}

.title {
  font-size: 18px;
}
@media (min-width: 1280px) {
  .title {
    grid-column: 1/-1;
  }
}

@media (min-width: 1280px) {
  .button {
    margin-top: 24px;
  }
}