.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  padding: 70px 45px 20px 45px;
  transform: translateX(100%);
  transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  z-index: 1032;
  margin-top: 69px;
  background-color: #131922;
  color: white;
}
@media (min-width: 640px) {
  .drawer {
    width: 400px;
  }
}

.visible {
  transform: translateX(0);
}

.close {
  display: flex;
  align-items: center;
  position: absolute;
  top: 15px;
  left: 10px;
  border: 0;
  background-color: transparent;
  color: white;
  font-size: 16px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 20px;
  border: 1px solid white;
  font-size: 14px;
  margin-right: 10px;
}

.blur {
  filter: blur(5px);
}