.wrapper {
  display: grid;
  grid-template-areas: "empty" "value" "name" "timestamp";
}
@media (min-width: 768px) {
  .wrapper {
    grid-template-areas: "value empty" "name timestamp";
    grid-template-columns: 250px 1fr;
  }
}

.paramValue {
  grid-area: value;
  font-weight: 100;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
}

.paramName {
  grid-area: name;
  font-weight: 800;
  font-size: 16px;
  text-transform: uppercase;
}

.timestamp {
  grid-area: timestamp;
  font-weight: 100;
  font-size: 15px;
}